// Angular
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";

// sales modules
import { ProposalAssistantApiModule } from "a3dn-proposalassistant-lib";
import { SalesTunnelWrapperApiModule } from "a3dn-salestunnelwrapper-lib";
import { ProposalTunnelApiModule } from "a3dn-proposaltunnel-lib";
import { SalesSharedApiModule } from "a3dn-salesshared-lib";
import { NeedsCollectionApiModule } from "a3dn-needscollection-lib";
import { SalesConfigurationApiModule } from "a3dn-salesconfiguration-lib";
import { OpprtunityApiModule } from "a3dn-opportunities-lib";

// Auth
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CustomerFieldApiModule } from "a3dn-customers-lib";
import { WebsiteApiModule, ownsalesApiModule } from "a3dn-ownsales-lib";
import { ReportApiModule } from "a3dn-reports-lib";

import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { environment } from "../environments/environment";
import { CoreModule } from "./_core/core.module";
import { AuthHttpInterceptor } from "./_core/interceptor/api-intercepteur";

import { documentModule, ticketsApiModule } from "ticket-domain-lib";

import { DocumentApiModule } from "a3dn-documents-lib";

import { ContractRecordApiModule } from "a3dn-contractrecord-lib";
import { InsurersApiModule } from "a3dn-insurers-lib";
import { SinisterConfigApiModule } from "a3dn-sinisterconfig-lib";
import { contratsdiversApiModule } from "a3dn-misccontracts-lib";
import { LayoutApiModule } from "a3dn-layout-lib";
import { ProductsApiModule } from "a3dn-products-lib";
import { UsersApiModule } from "a3dn-users-lib";

import { AuthentificationApiModule } from "a3dn-authentification-lib";

import { A3dnSupportModule, SupportLibApiModule } from "a3dn-support";

import { AutomationsApiModule } from "a3dn-automations-lib";
import { NgxMaskModule } from "ngx-mask";
import { IntercepteurFrontEndModule } from "./_core/api-client/intercepteur-front-end.module";

import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { AccountApiModule } from "a3dn-account-lib";
import { agendaApiModule } from "a3dn-agenda-lib";
import { AmendmentApiModule } from "a3dn-amendment-lib";
import { AutorisationsApiModule } from "a3dn-authorizations-lib";
import { BusinessProviderApiModule } from "a3dn-businessprovider-lib";
import { CatalogueApiModule } from "a3dn-catalogue-lib";
import { ContractPortfolioApiModule } from "a3dn-contractsportfolio-lib";
import { ContractPipelineApiModule } from "a3dn-contractpipeline-lib";
import { CustomerPortfolioApiModule } from "a3dn-customersportfolio-lib";
import { DirectDebitOpenApiModule } from "a3dn-direct-debit-lib";
import { DocumentSharedApiModule } from "a3dn-documentsshared-lib";
import { InlineSurveyApiModule, InlineSurveyFormModule } from "a3dn-inlinesurveys-lib";
import { OwnReferencialsApiModule } from "a3dn-ownreferencials-lib";
import { renewalsApiModule } from "a3dn-renewals-lib";
import { RevenuesApiModule } from "a3dn-revenues-lib";
import { saabonnementsApiModule } from "a3dn-sasubscriptions-lib";
import { SaTrialsApiModule } from "a3dn-satrials-lib";
import { SearchApiModule } from "a3dn-search-lib";
import { SettlementsApiModule } from "a3dn-settlements-lib";
import { A3dnUserService, SharedApiModule } from "a3dn-shared-lib";
import { SignatureApiModule } from "a3dn-signature-lib";
import { SinisterRecordApiModule } from "a3dn-sinisterrecord-lib";
import { SMSApiModule } from "a3dn-sms-lib";
import { AbonnementsApiModule } from "a3dn-subscriptions-lib";
import { SurveyEditorApiModule } from "a3dn-surveyeditor-lib";
import { UnpaidsApiModule } from "a3dn-unpaid-lib";
import { MailingApiModule } from "a3dn-mailing-lib";
import { sinistersPortfolioApiModule } from "a3dn-sinistersportfolio-lib";
import { ExpertiseApiModule } from "a3dn-expertise-lib";
import { InterventionsApiModule } from "a3dn-interventions-lib";
import { ContratAssistantApiModule } from "a3dn-contractassistant-lib";
import { BlockUIModule } from "ng-block-ui";
import { ImportContratsApiModule } from "a3dn-contractsimport-lib";
import { CommissionsApporteursApiModule } from "a3dn-providercommissions-lib";
import { Contract360ApiModule } from "a3dn-contract360-lib";
import { Sinistre360ApiModule } from "a3dn-sinister360-lib";
import { NotificationsApiModule } from "a3dn-notifications-lib";
import { SideNavService } from "./app-user/services/side-nav.service";
import { PanelService } from "./app-user/services/panel.service";
import { ModalNotificationSignalRService } from "a3dn-notifications-lib";
import { NgxMatColorPickerModule } from "@angular-material-components/color-picker";
import { EmailAssistantApiModule, NewMessageModule } from "a3dn-emailassistant-lib";
import { SinisterPipelineApiModule } from "a3dn-sinisterpipeline-lib";

import { SettlementsTunnelApiModule } from "a3dn-settlementstunnel-lib";
import { TermsImportApiModule } from "a3dn-termsimport-lib";
import { ComplianceApiModule } from "a3dn-compliance-lib";
import { SurveyConfigApiModule } from "a3dn-surveyconfig-lib";
import { EmailTemplatesApiModule } from "a3dn-emailtemplates-lib";
import { RatingApiModule } from "a3dn-rating-lib";
import { DealDetailApiModule } from "a3dn-dealdetail-lib";
import { TelephonyApiModule } from "a3dn-telephony-lib";
import { MessagerieTransactionnelleApiModule } from "a3dn-satransactionalmessaging-lib";
import { DocumentTemplatesApiModule } from "a3dn-documenttemplates-lib";

export function HttpLoaderFactory(http: HttpClient) {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: "./assets/i18n/auth/", suffix: ".json" },
		{ prefix: "./assets/i18n/_library/components/sc-phone-number/", suffix: ".json" },
		{ prefix: "./assets/i18n/src/customer-smart-lib/sc-personal-information/", suffix: ".json" },
		{ prefix: "./assets/i18n/project/a3dn-contact-lib/src/lib/contart-card/", suffix: ".json" },
		{ prefix: "./assets/i18n/_library/a3dn-customers-lib/", suffix: ".json" },
		{ prefix: "./assets/i18n/project/a3dn-contact-lib/src/lib/contart-card/", suffix: ".json" },
		{ prefix: "./assets/i18n/project/a3dn-contact-lib/src/lib/quote-for-conversion/", suffix: ".json" },
		{
			prefix: "./assets/i18n/project/libraries/src/direct-debits-lib/direct-debit-list/",
			suffix: ".json",
		},
		{
			prefix: "./assets/i18n/project/libraries/src/direct-debits-lib/new-direct-debit-order/",
			suffix: ".json",
		},
	]);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		CoreModule,

		NgbModule,
		AuthentificationApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		DocumentTemplatesApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SettlementsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		UnpaidsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		MailingApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		EmailTemplatesApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		CustomerFieldApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		SalesConfigurationApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ProposalAssistantApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SalesSharedApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		DealDetailApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		ProposalTunnelApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SalesTunnelWrapperApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		InlineSurveyFormModule,

		DocumentApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		DocumentSharedApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		RevenuesApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		ticketsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		documentModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		ContractRecordApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		InsurersApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SinisterConfigApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		ProductsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		UsersApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		A3dnSupportModule.forRoot(environment.production),
		SupportLibApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		IntercepteurFrontEndModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		DirectDebitOpenApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		BusinessProviderApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		AmendmentApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		sinistersPortfolioApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SinisterPipelineApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		NgxMaskModule.forRoot(),
		AutomationsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ReportApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SMSApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SignatureApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		CatalogueApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		contratsdiversApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		LayoutApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		AppRoutingModule,
		AutorisationsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SearchApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		AbonnementsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SharedApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		agendaApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		AccountApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ownsalesApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SaTrialsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		saabonnementsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SinisterRecordApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		renewalsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		OwnReferencialsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		WebsiteApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		CustomerPortfolioApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ContractPortfolioApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		OpprtunityApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		SurveyEditorApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		NeedsCollectionApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		InlineSurveyApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ExpertiseApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		InterventionsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		CommissionsApporteursApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),

		ContratAssistantApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ImportContratsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		Contract360ApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		Sinistre360ApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		NotificationsApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		EmailAssistantApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ContractPipelineApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SettlementsTunnelApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		TermsImportApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		ComplianceApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		SurveyConfigApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		RatingApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		BlockUIModule.forRoot(),
		NewMessageModule.forRoot(environment.production),
		TelephonyApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
		MessagerieTransactionnelleApiModule.forRoot({ rootUrl: environment.API_BASE_URL }),
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		AuthHttpInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true,
		},
		{ provide: DEFAULT_CURRENCY_CODE, useValue: "EUR" },
		{ provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
		{ provide: "IPanelService", useClass: PanelService },
		{ provide: "ISideNavService", useClass: SideNavService },
		A3dnUserService,
		ModalNotificationSignalRService,
		NgxMatColorPickerModule,
	],

	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {
		registerLocaleData(localeFr);
	}
}
